
















































import { Component, Vue } from 'vue-property-decorator'
import { getsucceedCase } from '@/api/home'
@Component({
  components: {}
})
export default class Success extends Vue {
  PageImgList = []
  succeedCaseVosList = []
  created() {
    getsucceedCase().then((res) => {
      this.PageImgList = res.data.succeedCaseImg.split(',')
      res.data.succeedCaseVosList.forEach((item: any) => {
        item.identification = true
      })
      this.succeedCaseVosList = res.data.succeedCaseVosList
    })
  }
}
